<!--
 * @Author: lvjiaqi5656 1665794034@qq.com
 * @Date: 2023-09-15 15:36:09
 * @LastEditors: lvjiaqi5656 1665794034@qq.com
 * @LastEditTime: 2023-12-11 08:30:21
 * @FilePath: \chengshineilao\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div id="app" :style="styles">
		<router-view :key="$route.fullPath"></router-view>
	</div>
</template>

<script>
	import {
		toRefs,
		reactive,
		ref,
		watch,
		onBeforeMount
	} from "vue";
	export default {
		name: 'App',
		setup() {
			const state = reactive({
				styles: ''
			})
			window.addEventListener("resize", function () {
				state.style = 'width:' + document.body.clientWidth
			});
			return {
				...toRefs(state)
			}
		}
	}
</script>

<style>
	@import url('./assets/style/publicCSS.css');

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #fff;
		margin-top: 0;
		height: 100%;
	}

	html,
	body {
		margin: 0;
		height: 100%;
	}

	.hAndWFull {
		height: 100%;
		width: 100%;
	}

	.centerAndMiddle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.startAndMiddle {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.endAndStart {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}

	.endAndMiddle {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.centerAndStart {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.betweenAndMiddle {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.aroundAndMiddle {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.column {
		flex-direction: column;
	}

	.centerAndStart {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.centerAndEvently {
		display: flex;
		justify-content: center;
		align-items: space-evently;
	}

	.aroundAndMiddle {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.aroundAndAround {
		display: flex;
		align-items: space-around;
		justify-content: space-around;
	}

	.centerAndEnd {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.tableCommonSty table,
	tr,
	td {
		border: 1px solid #fff;
	}

	.el-pagination button.is-disabled,
	.el-pagination button:disabled {
		background-color: transparent !important;
	}

	.el-pager li {
		background: transparent !important;
		/* color: #fff !important; */
	}

	.el-pagination {
		background-color: transparent !important;
	}

	.el-pagination button {
		background-color: transparent !important;
		color: #fff !important;
	}

	.el-tree {
		background: transparent !important;
		color: #fff !important;
	}

	.el-tree-node:focus>.el-tree-node__content {
		background: transparent !important;
	}

	.el-tree-node__content:hover,
	.el-input__wrapper {
		background: transparent !important;
	}

	/* selsect下拉框  树状形态 */
	.selectBack /deep/ .el-input__wrapper {
		/* background-color: #0756FF; */
		background-color: rgba(7, 86, 255, 0.3) !important;
		border: none;
		box-shadow: none;
		color: #fff;
		box-shadow: none !important;
	}

	/* 带 disabled */
	.selectBack /deep/ .is-disabled .el-input__wrapper {
		box-shadow: none !important;
	}

	.selectBack /deep/ .el-input__wrapper:hover {
		background-color: rgba(7, 86, 255, 0.3);
		color: #fff;
		border: none !important;
		box-shadow: none !important
	}

	.selectBack /deep/ .el-input__inner {
		color: #ffffff;
		box-shadow: none !important;
        color: #fff;
        -webkit-text-fill-color: #ffffff !important;
	}

	.dropdown /deep/ .el-select-dropdown__wrap {
		background-color: none;
	}

	/* el-date-picker 日期选择器 */
	.eldatapicker {
		background-color: rgba(7, 86, 255, 0.3) !important;
		box-shadow: none !important;
	}

	/* 谷歌浏览器  去掉边框 */
	.eldatapicker /deep/ .el-input__wrapper {
		box-shadow: none !important;

	}

	.eldatapicker /deep/ .el-range__icon {
		color: #ffffff !important;
	}

	.eldatapicker /deep/ .el-input__inner {
		color: #fff !important;
	}

	.eldatapicker {
		color: #fff !important;
	}

	.el-date-editor .el-range-separator {
		color: #fff !important
	}

	.selector {
		cursor: pointer;
	}

	.trangle {
		width: 0;
		height: 0;
		border-top: 5px solid transparent;
		border-right: 5px solid transparent;
		border-left: 5px solid #fff;
		border-bottom: 5px solid transparent;
	}

	.chosenSty {
		color: #fff;
		background-color: #0894FF;
		width: 70px;
		height: 24px;
		border: #1D80DA 1px solid;
	}

	.unChosenSty {
		color: #C3E1FF;
		background-color: transparent;
		border: #1D80DA 1px solid;
		width: 70px;
		height: 24px;
	}

	.homeBg {
		background: url('/src/assets/home/背景图@2x.png') 100% 100%/100% 100%;
	}

	.chosenFilter {
		margin-left: 10px;
		border-radius: 4px;
		height: 32px;
		box-sizing: border-box;
	}

	.blueSty {
		background-color: rgba(62, 162, 255, 1);
	}

	.lightBlueSty {
		background-color: rgba(62, 162, 255, 0.3);
	}

	.greenSty {
		background-color: rgba(25, 223, 120, 1);
	}

	.lightGreenSty {
		background-color: rgba(25, 223, 120, 0.3);
	}

	.pinkSty {
		background-color: rgba(255, 84, 124, 1);
	}

	.lightPinkSty {
		background-color: rgba(255, 84, 124, 0.3);
	}

	.yellowSty {
		background-color: rgba(255, 199, 72, 1);
	}

	.lightYellowSty {
		background-color: rgba(255, 199, 72, 0.3);
	}

	.orangeSty {
		background-color: rgba(255, 132, 72, 1);
	}

	.lightOrangeSty {
		background-color: rgba(255, 132, 72, 0.3);
	}

</style>