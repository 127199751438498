export function parseTime(time, fm) {
    // 解析时间  time: 时间戳或者实践对象 fm: 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
    if (arguments.length === 0) {
        return null;
    }
    if(time===null){
        return '无'
    }
    // const format = fm || "{y}-{m}-{d} {h}:{i}:{s}";
    const format = fm || "{y}-{m}-{d}";
    let date;
    if (typeof time === "object") {
        date = time;
    } else {
        if (("" + time).length === 10) time = parseInt(time) * 1000;
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const time_str = format.replace(
        /{(y|m|d|h|i|s|a)+}/g,
        (result, key) => {
            let value = formatObj[key];
            if (key === "a")
                return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
            if (result.length > 0 && value < 10) {
                value = "0" + value;
            }
            return value || 0;
        }
    );
    return time_str;
}