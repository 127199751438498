/*
 * @Author: caozhongliang 2377083360@qq.com
 * @Date: 2023-12-12 09:20:14
 * @LastEditors: caozhongliang 2377083360@qq.com
 * @LastEditTime: 2024-01-16 09:38:14
 * @FilePath: \newPCProject\newStyProject\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from 'vuex'

const store = createStore({
    state() {
        return {
            name: '',
            closeWebSocket: '效果图',
            isChosenLatern: null,
            tempHidden: false, //显示隐藏左侧导航栏
            DetailsComponents: '',
            isShowECharts: false,
            tid: '',
            BackUpdate: '', //后台更新
            Jumpdisplay: '',
            ProjectLogo: false,
            scorlto: '',
            datePciinver: '',
            TaskProgress: 0, //进度管控
            sheep: 0,
            centerDialog: false,
            Jumpaudit: 0,
            JumpSafeAudit: 0,
            JumpQualityAudit: 0,
            JumpTaskAudit: 0,
            Echovalue: {

            },
            task: [],
            plidexec: '',
            Bplid: '',
            Cplid: '',
            popstart: null,
            popplid: null,
            early_warn_count: 0,
            TrigA: 1,
            TrigB: 1,
            TrigC: 1,
            zation: true,
            NodePay:1

        }
    },
    mutations: {
        setCloseWebSocket(state, newValue) {
            state.closeWebSocket = newValue
        },
        setIsChosenLatern(state, newValue) {
            state.isChosenLatern = newValue
        },
        setTempHidden(state, newValue) {
            state.tempHidden = newValue
        },
        setDetailsComponents(state, newValue) {
            state.DetailsComponents = newValue
        },
        setIsShowECharts(state, newValue) {
            state.isShowECharts = newValue
        },
        setTid(state, newValue) {
            state.tid = newValue
        },
        setbackground(state, newValue) {
            state.BackUpdate = newValue
        },
        Jumpdisplays(state, newValue) {
            state.Jumpdisplay = newValue
        },
        setProjectLogo(state, newValue) {
            state.ProjectLogo = newValue
        },

        // 退出登陆后初始化vuex的状态
        resetState(state) {

            state.name = ''
            state.closeWebSocket = '效果图'
            state.isChosenLatern = null
            state.tempHidden = false, //显示隐藏左侧导航栏
                state.DetailsComponents = ''
            state.isShowECharts = false
            state.tid = ''
            state.BackUpdate = '' //后台更新
            state.Jumpdisplay = ''
            state.ProjectLogo = false
        },
        scorlto(state, newValue) {
            state.scorlto = newValue
        },
        datePciinver(state, newValue) {
            state.datePciinver = newValue
        },
        TaskProgress(state, newValue) {
            state.TaskProgress = newValue
        },
        sheep(state, newValue) {
            state.sheep = newValue


        },
        centerDialog(state, newValue) {
            state.centerDialog = newValue

        },
        Jumpaudit(state, newValue) {

            state.Jumpaudit = newValue


        },
        JumpSafeAudit(state, newValue) {
            state.JumpSafeAudit = newValue
        },
        JumpQualityAudit(state, newValue) {
            state.JumpQualityAudit = newValue
        },
        JumpTaskAudit(state, newValue) {
            state.JumpTaskAudit = newValue
        },
        Echovalue(state, newValue) {

            state.Echovalue = newValue



        },
        task(state, newValue) {

            state.task = newValue



        },
        plidexec(state, newValue) {
            state.plidexec = newValue
        },
        Bplid(state, newValue) {
            state.Bplid = newValue
        },
        Cplid(state, newValue) {
            state.Cplid = newValue
        },
        popstart(state, newValue) {
            state.popstart = newValue
        },
        popplid(state, newValue) {
            state.popplid = newValue
        },
        early_warn_count(state, newValue) {
            state.early_warn_count = newValue
        },
        TrigA(state, newValue) {
            state.TrigA = newValue
        },
        TrigB(state, newValue) {
            state.TrigB = newValue
        },
        TrigC(state, newValue) {
            state.TrigC = newValue
        },
        zation(state, newValue) {
            state.zation = newValue
        },
        NodePay(state, newValue) {
            state.NodePay = newValue
        },

    },
    actions: {
        updateCloseWebSocket({ commit }, newValue) {
            commit('setCloseWebSocket', newValue)
        },
        updateIsChosenLatern({ commit }, newValue) {
            commit('setIsChosenLatern', newValue)
        },
        updateTempHidden({ commit }, newValue) {
            commit('setTempHidden', newValue)
        },
        updateDetailsComponents({ commit }, newValue) {
            commit('setDetailsComponents', newValue)
        },
        updateIsShowECharts({ commit }, newValue) {
            commit('setIsShowECharts', newValue)
        },
        updateTid({ commit }, newValue) {
            commit('setTid', newValue)
        },
        setbackg({ commit }, newValue) {
            commit('setbackground', newValue)
        },
        Jumpdisplaysd({ commit }, newValue) {
            commit('Jumpdisplays', newValue)
        },
        updateProjectLogo({ commit }, newValue) {
            commit('setProjectLogo', newValue)
        },
        logout({ commit }) {
            // 执行退出登录的逻辑，清除用户相关的数据等

            // 调用 resetState 方法重置 Vuex 的状态
            commit('resetState');
        },
        scorltod({ commit }, newValue) {
            commit('scorlto', newValue)
        },
        Pciinvers({ commit }, newValue) {
            commit('datePciinver', newValue)
        },
        Progress({ commit }, newValue) {
            commit('TaskProgress', newValue)
        },
        sheepA({ commit }, newValue) {
            commit('sheep', newValue)
        },
        centerDialogA({ commit }, newValue) {
            commit('centerDialog', newValue)
        },
        JumpauditA({ commit }, newValue) {
            commit('Jumpaudit', newValue)
        },
        JumpSafeAuditA({ commit }, newValue) {
            commit('JumpSafeAudit', newValue)
        },
        JumpQualityAuditA({ commit }, newValue) {
            commit('JumpQualityAudit', newValue)
        },
        JumpTaskAuditA({ commit }, newValue) {
            commit('JumpTaskAudit', newValue)
        },
        EchovalueA({ commit }, newValue) {
            commit('Echovalue', newValue)
        },
        taskA({ commit }, newValue) {
            commit('task', newValue)
        },
        plidexecA({ commit }, newValue) {
            commit('plidexec', newValue)
        },
        BplidA({ commit }, newValue) {
            commit('Bplid', newValue)
        },
        CplidA({ commit }, newValue) {
            commit('Cplid', newValue)
        },
        popstartA({ commit }, newValue) {
            commit('popstart', newValue)
        },
        popplidA({ commit }, newValue) {
            commit('popplid', newValue)
        },
        early_warn_countA({ commit }, newValue) {
            commit('early_warn_count', newValue)
        },
        TrigAA({ commit }, newValue) {
            commit('TrigA', newValue)
        },
        TrigBB({ commit }, newValue) {
            commit('TrigB', newValue)
        },
        TrigCC({ commit }, newValue) {
            commit('TrigC', newValue)
        },
        zationA({ commit }, newValue) {
            commit('zation', newValue)
        },
        
        NodePayA({ commit }, newValue) {
            commit('NodePay', newValue)
        },
    }
})
export default store