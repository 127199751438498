/*
 * @Author: caozhongliang 2377083360@qq.com
 * @Date: 2023-11-13 15:10:25
 * @LastEditors: caozhongliang 2377083360@qq.com
 * @LastEditTime: 2024-01-02 15:56:29
 * @FilePath: \newPCProject\newStyProject\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createWebHashHistory, createRouter } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'

  },
  {
    path: '/foreSelectProject',
    name: 'foreSelectProject',
    component: () => import('@/views/foreSelectProject/index.vue'),
  }, {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [
      {
        path: '/components/rightLaternContent',
        name: 'rightLaternContent',
        component: () => import('@/views/home/components/main/edit/right/rightLaternContent.vue'),
        meta: { keepAlive: true },
      },
      {
        path: '/components/onLineConference',
        name: 'onLineConference',
        component: () => import('@/views/home/components/main/edit/middle/LaternComponent/onlineConference.vue')
      }
    ]
  }, {
    path: '/details',
    name: 'details',
    component: () => import('@/views/details/index.vue'),
    meta: { keepAlive: true },
  }, {
    path: '/background',
    name: 'background',
    component: () => import('@/views/background'),
  }, {
    path: '/progressComparison',
    name: 'progressComparison',
    component: () => import('@/views/details/components/progressControl/progressComparison.vue')
  }, {
    path: '/NewBackend',
    name: 'NewBackend',
    component: () => import('@/views/NewBackend'),
  },
]

const router = createRouter({
  // 路由的模式
  history: createWebHashHistory(),
  // 路由规则
  routes
})
// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  //当前在登录页面  to.matched.some(record => record.meta.requiresAuth)
  if (from.path === '/home' && to.path === '/background') {
    next(false); // 阻止路由跳转
  } else if (from.path === '/background' && to.path === '/home') {
    next(false); // 阻止路由跳转
  } else {
    next(); // 允许路由正常跳转
  }
});
export default router
